import React, { useContext } from 'react';
import { PiletApi } from '@sharefiledev/sharefile-appshell';

export interface AppContext {
	piletApi: PiletApi;
}

const AppContext = React.createContext<AppContext>({
	piletApi: {} as PiletApi,
});

export const useAppContext = () => {
	return useContext(AppContext);
};

export const AppContextProvider = AppContext.Provider;
