import { CSSProperties } from 'react';
import {
	OverlayDrawerOptions,
	OverlayModalOptions,
} from '@sharefiledev/sharefile-appshell';

export type DefaultModalConfiguration = {
	isFullscreen?: boolean;
	titleText?: string;
	overflowYOverride?: CSSProperties['overflowY'];
};

export type DefaultDrawerConfiguration = {
	titleText?: string;
	width?: string;
};

export const FLOW_WEB_SAFE_OVERLAY_Z_INDEX = 10;

export function getDefaultModalProps(
	props: DefaultModalConfiguration
): Partial<OverlayModalOptions<any>> {
	const bodyStyles = {
		marginTop: '20px',
		overflowY: props.overflowYOverride ?? 'auto',
		overflowX: 'hidden',
		...(props.isFullscreen
			? {
					height: 'calc(95vh - 86px)',
					margin: '20px 0px',
			  }
			: {}),
	};

	return {
		modalProps: {
			width: props.isFullscreen ? '95vw' : '640px',
			title: props.titleText,
			footer: null,
			centered: true,
			zIndex: FLOW_WEB_SAFE_OVERLAY_Z_INDEX,
			styles: {
				body: bodyStyles,
			},
		},
	};
}

export function getDefaultDrawerProps(
	props: DefaultDrawerConfiguration
): Partial<OverlayDrawerOptions<any>> {
	const headerStyles = {
		display: 'none',
	};

	return {
		drawerProps: {
			title: props.titleText,
			placement: 'left',
			size: 'large',
			width: props.width,
			push: false,
			zIndex: FLOW_WEB_SAFE_OVERLAY_Z_INDEX,
			styles: {
				header: headerStyles,
				body: { padding: 0 },
			},
		},
	};
}

export type SubDrawerConfiguration = DefaultDrawerConfiguration & {
	open: boolean;
	onClose: () => void;
};

export function getDefaultSubDrawerProps({
	titleText,
	open,
	onClose,
}: SubDrawerConfiguration): Partial<OverlayDrawerOptions<any>> {
	return {
		drawerProps: {
			title: titleText,
			placement: 'right',
			size: 'large',
			open,
			onClose,
		},
	};
}
