import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { getApiBaseUrl } from '../../helpers/getApiBaseUrl';
import { initializeSdk, PackageSdk } from './sdk';
import {
	GetPackagesParams,
	GetPackagesResponse,
	UpdatePackageParams,
	UpdatePackageResponse,
} from './types';

export class PackageClient {
	private initializedSDK: PackageSdk;

	public Initialize(sdk: PackageSdk) {
		this.initializedSDK = sdk;
	}

	public getPackages(params: GetPackagesParams): Promise<GetPackagesResponse> {
		return this.initializedSDK.getPackages(params);
	}

	public updatePackage(params: UpdatePackageParams): Promise<UpdatePackageResponse> {
		return this.initializedSDK.updatePackage(params);
	}
}

export const packageClient = new PackageClient();

export function configurePackageClient(app: PiletApi) {
	packageClient.Initialize(
		initializeSdk({
			baseUrl: getApiBaseUrl(app),
		})
	);
}
