import { exists } from '@utils';

export const localizeResourceToken = (resourceType: string, token: string) => {
	return `resource.${resourceType.replace(':', '_')}.${token}`;
};

export const localizeResourceTokenWithFallback = (
	resourceType: string,
	token: string,
	fallbackType: string
) => {
	const resourceToken = localizeResourceToken(resourceType, token);
	const defaultToken = localizeResourceToken(fallbackType, token);
	return exists(resourceToken) ? resourceToken : defaultToken;
};

export const localizeResourceTokenOrDefaultToken = (
	resourceType: string,
	token: string,
	defaultToken: string
) => {
	const resourceToken = localizeResourceToken(resourceType, token);
	return exists(resourceToken) ? resourceToken : defaultToken;
};
