/**
 * These are all runtime dependencies. Any breaking changes
 * will need a migration path first!
 */
import type {} from '@sharefiledev/sharefile-appshell'; // force tsc to resolve this package
// Note: If additional dependencies are added, modify the package.json entry point paths to reflect new folder structure

const prefix = 'urn';
const owner = 'package-pilet';
const modalNamespace = 'sfmodal';
// const uiBlockNamespace = 'block';
const drawerNamespace = 'sfdrawer';

export type EditPackageOverlayParams = {
	containerRID: string;
	containerType?: string;
	onSave?: (data?: any) => void;
	onCancel?: () => void;
};

export const packagePiletExtensionSlots = {
	packageEditorTitleSlot:
		`${prefix}:${owner}:${modalNamespace}:package-editor-title-slot` as const,
};

export const packagePiletBlocks = {};

export const packagePiletOverlays = {
	editPackageModal: `${prefix}:${owner}:${modalNamespace}:edit-package-modal` as const,
	editPackageDrawer: `${prefix}:${owner}:${drawerNamespace}:edit-package-drawer` as const,
};

export const internalPackagePiletOverlays = {};

declare module '@sharefiledev/sharefile-appshell' {
	interface PiralCustomExtensionSlotMap {}

	interface PiralCustomOverlayOptionsMap {
		[packagePiletOverlays.editPackageModal]: EditPackageOverlayParams;
		[packagePiletOverlays.editPackageDrawer]: EditPackageOverlayParams;
	}

	interface PiralCustomOverlayDataMap {}
}
