/* tslint:disable */
/* This file was generated by @citrite/translate. Manual changes will be lost. */
import { Language } from '@citrite/translate';
export type locale = 'de' | 'en' | 'es' | 'fr' | 'it' | 'ja' | 'nl' | 'pt-BR';
export const languages: Language[] = [
  {
    "key": "de",
    "content": {
      "common": {
        "cancel": "Abbrechen",
        "copy": "Kopieren",
        "delete": "Löschen",
        "delete_success": "Der Löschvorgang wurde abgeschlossen",
        "delete_failed": "Fehler beim Löschen",
        "duplicate": "Duplizieren",
        "edit": "Bearbeiten",
        "preview": "Vorschau",
        "resources": "Ressourcen",
        "save": "Speichern",
        "save_changes": "Änderungen speichern",
        "unknown": "Unbekannt",
        "menu": "Menü",
        "create_new": "Erstellen"
      },
      "messages": {
        "error_loading_resources": "Ihre Ressourcen konnten nicht geladen werden. Versuchen Sie es erneut.",
        "error_saving_json": "Ihre Bearbeitungen konnten nicht gespeichert werden",
        "error_saving_changes": {
          "message": "Ihre Änderungen konnten nicht gespeichert werden",
          "description": "Versuchen Sie es erneut."
        },
        "no_resource_selected": "Sie haben nichts ausgewählt",
        "saved_changes": "Gespeicherte Änderungen",
        "select_resource": "Wählen Sie in der Seitenleiste etwas zum Anzeigen oder Bearbeiten aus oder erstellen Sie etwas Neues.",
        "unable_to_edit": "Diese Ressource kann derzeit nicht bearbeitet werden. Wählen Sie stattdessen eine andere Ressource zum Bearbeiten aus."
      },
      "prompts": {
        "edit_package": "Paket bearbeiten",
        "exit_editor": {
          "cancel": "Ohne zu speichern schließen",
          "message": "Möchten Sie diese Seite wirklich schließen? Ihre nicht gespeicherten Änderungen gehen verloren.",
          "ok": "Speichern und schließen",
          "title": "Beenden ohne zu speichern"
        },
        "save_edits": "Bearbeitungen speichern",
        "show_code": "Code anzeigen"
      },
      "resource": {
        "sharefile_datatable": {
          "delete": {
            "failed": "Die Tabelle konnte nicht gelöscht werden",
            "success": "Gelöschte Tabelle",
            "title": "Tabelle löschen"
          },
          "label": "Tabelle"
        },
        "sharefile_document": {
          "delete": {
            "failed": "Das Dokument konnte nicht gelöscht werden",
            "success": "Dokument gelöscht",
            "title": "Dokument löschen"
          },
          "label": "Dokument"
        },
        "sharefile_flow": {
          "delete": {
            "failed": "Der Workflow konnte nicht gelöscht werden",
            "success": "Gelöschter Workflow",
            "title": "Workflow löschen"
          },
          "label": "Workflow"
        },
        "sharefile_inforeq": {
          "delete": {
            "failed": "Die Informationsanforderung konnte nicht gelöscht werden",
            "success": "Gelöschte Informationsanforderung",
            "title": "Informationsanforderung löschen"
          },
          "title": "Informationsanforderung löschen",
          "label": "Informationsanforderung"
        },
        "sharefile_package": {
          "delete": {
            "failed": "Das Paket konnte nicht gelöscht werden",
            "success": "Gelöschtes Paket",
            "title": "Paket löschen"
          },
          "delete_from": {
            "try_again": "Versuchen Sie erneut, diesen Typ aus dem Paket zu entfernen",
            "confirm": "Möchten Sie {<name>} wirklich löschen? Wird aus dem Paket entfernt.",
            "confirm_container": "Möchten Sie {<name>} wirklich löschen? Das Element wird mitsamt allen abhängigen Elementen aus dem Paket entfernt."
          },
          "label": "Paket"
        },
        "sharefile_project": {
          "delete": {
            "failed": "Das Projekt konnte nicht gelöscht werden",
            "success": "Projekt gelöscht",
            "title": "Projekt löschen"
          },
          "label": "Projekt"
        },
        "sharefile_requestlist": {
          "delete": {
            "failed": "Die Dokumentanforderung konnte nicht gelöscht werden",
            "success": "Gelöschte Dokumentanforderung",
            "title": "Dokumentanforderung löschen"
          },
          "label": "Dokumentanforderung"
        },
        "sharefile_template": {
          "delete": {
            "failed": "Die Vorlage konnte nicht gelöscht werden",
            "success": "Gelöschte Vorlage",
            "title": "Vorlage löschen"
          },
          "delete_from": {
            "try_again": "Versuchen Sie erneut, das Element aus der Vorlage zu entfernen",
            "confirm": "Möchten Sie {<name>} wirklich löschen? Wird aus der Vorlage entfernt.",
            "confirm_container": "Möchten Sie {<name>} wirklich löschen? Das Element wird mitsamt allen abhängigen Elementen aus dem Paket entfernt."
          },
          "label": "Vorlage"
        },
        "sharefile_todos": {
          "delete": {
            "failed": "Die Aufgabe konnte nicht gelöscht werden",
            "success": "Gelöschte Aufgabe",
            "title": "Aufgabe löschen"
          },
          "label": "Aufgabe"
        }
      }
    }
  },
  {
    "key": "en",
    "content": {
      "common": {
        "cancel": "Cancel",
        "copy": "Copy",
        "delete": "Delete",
        "delete_success": "Delete completed successfully",
        "delete_failed": "Delete failed",
        "duplicate": "Duplicate",
        "edit": "Edit",
        "preview": "Preview",
        "resources": "Resources",
        "save": "Save",
        "save_changes": "Save changes",
        "unknown": "unknown",
        "menu": "Menu",
        "create_new": "Create new"
      },
      "messages": {
        "error_loading_resources": "We were unable to load your resources. Please try again.",
        "error_saving_json": "Your edits could not be saved",
        "error_saving_changes": {
          "message": "We couldn't save your changes",
          "description": "Please try again."
        },
        "no_resource_selected": "You don't have anything selected",
        "saved_changes": "Saved changes",
        "select_resource": "Choose something in the side panel to view or edit, or create something new.",
        "unable_to_edit": "This resource is not currently available to edit. Select a different resource to edit instead."
      },
      "permissions": {
        "share": "Share",
        "template": {
          "title": "Share Template",
          "share_success": "Template has been shared"
        }
      },
      "prompts": {
        "edit_package": "Edit package",
        "exit_editor": {
          "cancel": "Close without saving",
          "message": "Are you sure you want to close this page? Any unsaved changes will be lost.",
          "ok": "Save and close",
          "title": "Exit without saving"
        },
        "save_edits": "Save edits",
        "show_code": "Show code"
      },
      "resource": {
        "sharefile_datatable": {
          "delete": {
            "failed": "We weren’t able to delete the table",
            "success": "Deleted table",
            "title": "Delete table"
          },
          "label": "Table"
        },
        "sharefile_document": {
          "delete": {
            "failed": "We weren’t able to delete the document",
            "success": "Deleted document",
            "title": "Delete document"
          },
          "label": "Document"
        },
        "sharefile_flow": {
          "delete": {
            "failed": "We weren’t able to delete the workflow",
            "success": "Deleted workflow",
            "title": "Delete workflow"
          },
          "label": "Workflow"
        },
        "sharefile_inforeq": {
          "delete": {
            "failed": "We weren’t able to delete the information request",
            "success": "Deleted information request",
            "title": "Delete information request"
          },
          "title": "Delete information request",
          "label": "Information request"
        },
        "sharefile_package": {
          "delete": {
            "failed": "We weren’t able to delete the package",
            "success": "Deleted package",
            "title": "Delete package"
          },
          "delete_from": {
            "try_again": "Please try again to remove it from the package",
            "confirm": "Are you sure you want to delete {<name>}? This will remove it from the package.",
            "confirm_container": "Are you sure you want to delete {<name>}? This will remove it and anything that belongs to it from the package."
          },
          "label": "Package"
        },
        "sharefile_project": {
          "delete": {
            "failed": "We weren’t able to delete the project",
            "success": "Deleted project",
            "title": "Delete project"
          },
          "label": "Project"
        },
        "sharefile_requestlist": {
          "delete": {
            "failed": "We weren’t able to delete the document request",
            "success": "Deleted document request",
            "title": "Delete document request"
          },
          "label": "Document request"
        },
        "sharefile_template": {
          "delete": {
            "failed": "We weren’t able to delete the template",
            "success": "Deleted template",
            "title": "Delete template"
          },
          "delete_from": {
            "try_again": "Please try again to remove it from the template",
            "confirm": "Are you sure you want to delete {<name>}? This will remove it from the template.",
            "confirm_container": "Are you sure you want to delete {<name>}? This will remove it and anything that belongs to it from the package."
          },
          "label": "Template"
        },
        "sharefile_todos": {
          "delete": {
            "failed": "We weren’t able to delete the task",
            "success": "Deleted task",
            "title": "Delete task"
          },
          "label": "Task"
        }
      }
    }
  },
  {
    "key": "es",
    "content": {
      "common": {
        "cancel": "Cancelar",
        "copy": "Copiar",
        "delete": "Eliminar",
        "delete_success": "Eliminación completada correctamente",
        "delete_failed": "La eliminación falló",
        "duplicate": "Duplicar",
        "edit": "Modificar",
        "preview": "Vista previa",
        "resources": "Recursos",
        "save": "Guardar",
        "save_changes": "Guardar cambios",
        "unknown": "desconocido",
        "menu": "Menú",
        "create_new": "Crear nuevo"
      },
      "messages": {
        "error_loading_resources": "No pudimos cargar sus recursos. Inténtelo de nuevo.",
        "error_saving_json": "No se pudieron guardar las modificaciones",
        "error_saving_changes": {
          "message": "No pudimos guardar los cambios",
          "description": "Vuelva a intentarlo."
        },
        "no_resource_selected": "No tiene nada seleccionado",
        "saved_changes": "Cambios guardados",
        "select_resource": "Elija algo en el panel lateral para ver o modificar, o cree algo nuevo.",
        "unable_to_edit": "Este recurso no está disponible actualmente para modificación. Seleccione otro recurso para modificarlo en su lugar."
      },
      "prompts": {
        "edit_package": "Modificar paquete",
        "exit_editor": {
          "cancel": "Cerrar sin guardar",
          "message": "¿Seguro que quiere cerrar esta página? Los cambios no guardados se perderán.",
          "ok": "Guardar y cerrar",
          "title": "Salir sin guardar"
        },
        "save_edits": "Guardar modificaciones",
        "show_code": "Mostrar código"
      },
      "resource": {
        "sharefile_datatable": {
          "delete": {
            "failed": "No pudimos eliminar la tabla",
            "success": "Tabla eliminada",
            "title": "Eliminar tabla"
          },
          "label": "Tabla"
        },
        "sharefile_document": {
          "delete": {
            "failed": "No pudimos eliminar el documento",
            "success": "Documento eliminado",
            "title": "Eliminar documento"
          },
          "label": "Documento"
        },
        "sharefile_flow": {
          "delete": {
            "failed": "No pudimos eliminar el flujo de trabajo",
            "success": "Flujo de trabajo eliminado",
            "title": "Eliminar flujo de trabajo"
          },
          "label": "Flujo de trabajo"
        },
        "sharefile_inforeq": {
          "delete": {
            "failed": "No pudimos eliminar la solicitud de información",
            "success": "Solicitud de información eliminada",
            "title": "Eliminar solicitud de información"
          },
          "title": "Eliminar solicitud de información",
          "label": "Solicitud de información"
        },
        "sharefile_package": {
          "delete": {
            "failed": "No pudimos eliminar el paquete",
            "success": "Paquete eliminado",
            "title": "Eliminar paquete"
          },
          "delete_from": {
            "try_again": "Intente de nuevo quitarlo del paquete",
            "confirm": "¿Seguro que quiere eliminar {<name>}? Al hacerlo, se quitará del paquete.",
            "confirm_container": "¿Seguro que quiere eliminar {<name>}? Al hacerlo, se quitará, junto con todo lo que le pertenece, del paquete."
          },
          "label": "Paquete"
        },
        "sharefile_project": {
          "delete": {
            "failed": "No pudimos eliminar el proyecto",
            "success": "Proyecto eliminado",
            "title": "Eliminar proyecto"
          },
          "label": "Proyecto"
        },
        "sharefile_requestlist": {
          "delete": {
            "failed": "No pudimos eliminar la solicitud de documento",
            "success": "Solicitud de documento eliminada",
            "title": "Eliminar solicitud de documento"
          },
          "label": "Solicitud de documento"
        },
        "sharefile_template": {
          "delete": {
            "failed": "No pudimos eliminar la plantilla",
            "success": "Plantilla eliminada",
            "title": "Eliminar plantilla"
          },
          "delete_from": {
            "try_again": "Intente de nuevo quitarlo de la plantilla",
            "confirm": "¿Seguro que quiere eliminar {<name>}? Al hacerlo, se quitará de la plantilla.",
            "confirm_container": "¿Seguro que quiere eliminar {<name>}? Al hacerlo, se quitará, junto con todo lo que le pertenece, del paquete."
          },
          "label": "Plantilla"
        },
        "sharefile_todos": {
          "delete": {
            "failed": "No pudimos eliminar la tarea",
            "success": "Tarea eliminada",
            "title": "Eliminar tarea"
          },
          "label": "Tarea"
        }
      }
    }
  },
  {
    "key": "fr",
    "content": {
      "common": {
        "cancel": "Annuler",
        "copy": "Copier",
        "delete": "Supprimer",
        "delete_success": "Suppression terminée avec succès",
        "delete_failed": "Échec de la suppression",
        "duplicate": "Dupliquer",
        "edit": "Modifier",
        "preview": "Aperçu",
        "resources": "Ressources",
        "save": "Enregistrer",
        "save_changes": "Enregistrer les modifications",
        "unknown": "inconnu",
        "menu": "Menu",
        "create_new": "Créer"
      },
      "messages": {
        "error_loading_resources": "Nous n'avons pas pu charger vos ressources. Réessayez.",
        "error_saving_json": "Vos modifications n'ont pas pu être enregistrées",
        "error_saving_changes": {
          "message": "Nous n'avons pas pu enregistrer vos modifications",
          "description": "Veuillez réessayer."
        },
        "no_resource_selected": "Vous n'avez rien sélectionné",
        "saved_changes": "Modifications enregistrées",
        "select_resource": "Choisissez quelque chose dans le panneau latéral à afficher ou modifier, ou créez quelque chose.",
        "unable_to_edit": "Cette ressource n'est actuellement pas disponible pour être modifiée. Sélectionnez plutôt une autre ressource à modifier."
      },
      "prompts": {
        "edit_package": "Modifier un paquetage",
        "exit_editor": {
          "cancel": "Fermer sans enregistrer",
          "message": "Êtes-vous sûr de vouloir fermer cette page ? Toutes les modifications non enregistrées seront perdues.",
          "ok": "Enregistrer et fermer",
          "title": "Quitter sans enregistrer"
        },
        "save_edits": "Enregistrer les modifications",
        "show_code": "Afficher le code"
      },
      "resource": {
        "sharefile_datatable": {
          "delete": {
            "failed": "Nous n'avons pas pu supprimer la table",
            "success": "Table supprimée",
            "title": "Supprimer la table"
          },
          "label": "Tableau"
        },
        "sharefile_document": {
          "delete": {
            "failed": "Nous n'avons pas pu supprimer le document",
            "success": "Document supprimé",
            "title": "Supprimer le document"
          },
          "label": "Document"
        },
        "sharefile_flow": {
          "delete": {
            "failed": "Nous n'avons pas pu supprimer le workflow",
            "success": "Workflow supprimé",
            "title": "Supprimer workflow"
          },
          "label": "Workflow"
        },
        "sharefile_inforeq": {
          "delete": {
            "failed": "Nous n'avons pas pu supprimer la demande d'informations",
            "success": "Demande d'informations supprimée",
            "title": "Supprimer la demande d'informations"
          },
          "title": "Supprimer la demande d'informations",
          "label": "Demande d'informations"
        },
        "sharefile_package": {
          "delete": {
            "failed": "Nous n'avons pas pu supprimer le package",
            "success": "Package supprimé",
            "title": "Supprimer le package"
          },
          "delete_from": {
            "try_again": "Réessayez de supprimer l'élément du package",
            "confirm": "Êtes-vous sûr de vouloir supprimer l'élément {<name>} ? Il sera supprimé du package.",
            "confirm_container": "Êtes-vous sûr de vouloir supprimer l'élément {<name>} ? Il sera supprimé du package, ainsi que tout ce qui lui appartient."
          },
          "label": "Package"
        },
        "sharefile_project": {
          "delete": {
            "failed": "Nous n'avons pas pu supprimer le projet",
            "success": "Projet supprimé",
            "title": "Supprimer le projet"
          },
          "label": "Projet"
        },
        "sharefile_requestlist": {
          "delete": {
            "failed": "Nous n'avons pas pu supprimer la demande de document",
            "success": "Demande de document supprimée",
            "title": "Supprimer demande de document"
          },
          "label": "Demande de documents"
        },
        "sharefile_template": {
          "delete": {
            "failed": "Nous n'avons pas pu supprimer le modèle",
            "success": "Modèle supprimé",
            "title": "Supprimer le modèle"
          },
          "delete_from": {
            "try_again": "Réessayez de supprimer l'élément du modèle",
            "confirm": "Êtes-vous sûr de vouloir supprimer l'élément {<name>} ? Il sera supprimé du modèle.",
            "confirm_container": "Êtes-vous sûr de vouloir supprimer l'élément {<name>} ? Il sera supprimé du package, ainsi que tout ce qui lui appartient."
          },
          "label": "Modèle"
        },
        "sharefile_todos": {
          "delete": {
            "failed": "Nous n'avons pas pu supprimer la tâche",
            "success": "Tâche supprimée",
            "title": "Supprimer tâche"
          },
          "label": "Tâche"
        }
      }
    }
  },
  {
    "key": "it",
    "content": {
      "common": {
        "cancel": "Annulla",
        "copy": "Copia",
        "delete": "Elimina",
        "delete_success": "Eliminazione completata correttamente",
        "delete_failed": "Eliminazione non riuscita",
        "duplicate": "Duplica",
        "edit": "Modifica",
        "preview": "Anteprima",
        "resources": "Risorse",
        "save": "Salva",
        "save_changes": "Salva modifiche",
        "unknown": "sconosciuto",
        "menu": "Menu",
        "create_new": "Crea nuovo"
      },
      "messages": {
        "error_loading_resources": "Non è stato possibile caricare le tue risorse. Riprova.",
        "error_saving_json": "Impossibile salvare le modifiche",
        "error_saving_changes": {
          "message": "Non è stato possibile salvare le modifiche",
          "description": "Riprovare."
        },
        "no_resource_selected": "Non è selezionato nulla",
        "saved_changes": "Modifiche salvate",
        "select_resource": "Scegliere qualcosa nel pannello laterale per visualizzarlo o modificarlo oppure crearne uno nuovo.",
        "unable_to_edit": "Questa risorsa non è attualmente disponibile per la modifica. Selezionare una risorsa diversa da modificare."
      },
      "prompts": {
        "edit_package": "Modifica pacchetto",
        "exit_editor": {
          "cancel": "Chiudi senza salvare",
          "message": "Chiudere questa pagina? Tutte le modifiche non salvate andranno perse.",
          "ok": "Salva e chiudi",
          "title": "Esci senza salvare"
        },
        "save_edits": "Salva le modifiche",
        "show_code": "Mostra il codice"
      },
      "resource": {
        "sharefile_datatable": {
          "delete": {
            "failed": "Non siamo riusciti a eliminare la tabella",
            "success": "Tabella eliminata",
            "title": "Elimina tabella"
          },
          "label": "Tabella"
        },
        "sharefile_document": {
          "delete": {
            "failed": "Non siamo riusciti a eliminare il documento",
            "success": "Documento eliminato",
            "title": "Elimina documento"
          },
          "label": "Documento"
        },
        "sharefile_flow": {
          "delete": {
            "failed": "Non siamo riusciti a eliminare il flusso di lavoro",
            "success": "Flusso di lavoro eliminato",
            "title": "Elimina flusso di lavoro"
          },
          "label": "Flusso di lavoro"
        },
        "sharefile_inforeq": {
          "delete": {
            "failed": "Non siamo riusciti a eliminare la richiesta di informazioni",
            "success": "Richiesta di informazioni eliminata",
            "title": "Elimina richiesta informazioni"
          },
          "title": "Elimina richiesta informazioni",
          "label": "Richiesta di informazioni"
        },
        "sharefile_package": {
          "delete": {
            "failed": "Non siamo riusciti a eliminare il pacchetto",
            "success": "Pacchetto eliminato",
            "title": "Elimina pacchetto"
          },
          "delete_from": {
            "try_again": "Riprova a rimuoverlo dal pacchetto",
            "confirm": "Eliminare {<name>}? Questo lo rimuoverà dal pacchetto.",
            "confirm_container": "Eliminare {<name>}? In questo modo sarà \nrimosso dal pacchetto insieme a tutto ciò che gli appartiene."
          },
          "label": "Pacchetto"
        },
        "sharefile_project": {
          "delete": {
            "failed": "Non siamo riusciti a eliminare il progetto",
            "success": "Progetto eliminato",
            "title": "Elimina progetto"
          },
          "label": "Progetto"
        },
        "sharefile_requestlist": {
          "delete": {
            "failed": "Non siamo stati in grado di eliminare la richiesta di documenti",
            "success": "Richiesta di documenti eliminata",
            "title": "Elimina richiesta di documento"
          },
          "label": "Richiesta di documenti"
        },
        "sharefile_template": {
          "delete": {
            "failed": "Non siamo riusciti a eliminare il modello",
            "success": "Modello eliminato",
            "title": "Elimina modello"
          },
          "delete_from": {
            "try_again": "Riprovare a rimuoverlo dal modello",
            "confirm": "Eliminare {<name>}? Questo lo rimuoverà dal modello.",
            "confirm_container": "Eliminare {<name>}? In questo modo sarà \nrimosso dal pacchetto insieme a tutto ciò che gli appartiene."
          },
          "label": "Modello"
        },
        "sharefile_todos": {
          "delete": {
            "failed": "Non siamo riusciti a eliminare l'attività",
            "success": "Attività eliminata",
            "title": "Elimina attività"
          },
          "label": "Attività"
        }
      }
    }
  },
  {
    "key": "ja",
    "content": {
      "common": {
        "cancel": "キャンセル",
        "copy": "コピー",
        "delete": "削除",
        "delete_success": "削除が正常に終了しました",
        "delete_failed": "削除に失敗しました",
        "duplicate": "複製",
        "edit": "編集",
        "preview": "プレビュー",
        "resources": "リソース",
        "save": "保存",
        "save_changes": "変更の保存",
        "unknown": "不明",
        "menu": "メニュー",
        "create_new": "新規作成"
      },
      "messages": {
        "error_loading_resources": "リソースを読み込めません。再試行してください。",
        "error_saving_json": "編集内容を保存できませんでした",
        "error_saving_changes": {
          "message": "変更を保存できませんでした",
          "description": "再試行してください。"
        },
        "no_resource_selected": "何も選択されていません",
        "saved_changes": "保存された変更",
        "select_resource": "サイド パネルでいずれかを選択して表示または編集するか、新しく作成します。",
        "unable_to_edit": "このリソースは現在編集できません。代わりに別のリソースを選択して編集してください。"
      },
      "prompts": {
        "edit_package": "パッケージの編集",
        "exit_editor": {
          "cancel": "保存せずに閉じる",
          "message": "このページを閉じてもよろしいですか? 保存されていない変更は失われます。",
          "ok": "保存して閉じる",
          "title": "保存せずに終了する"
        },
        "save_edits": "編集内容を保存する",
        "show_code": "コードを表示する"
      },
      "resource": {
        "sharefile_datatable": {
          "delete": {
            "failed": "テーブルを削除できませんでした",
            "success": "テーブルを削除しました",
            "title": "テーブルを削除する"
          },
          "label": "表"
        },
        "sharefile_document": {
          "delete": {
            "failed": "ドキュメントを削除できませんでした",
            "success": "削除されたドキュメント",
            "title": "ドキュメントを削除する"
          },
          "label": "ドキュメント"
        },
        "sharefile_flow": {
          "delete": {
            "failed": "ワークフローを削除できませんでした",
            "success": "ワークフローを削除しました",
            "title": "ワークフローを削除する"
          },
          "label": "ワークフロー"
        },
        "sharefile_inforeq": {
          "delete": {
            "failed": "情報リクエストを削除できませんでした",
            "success": "情報リクエストを削除しました",
            "title": "情報リクエストを削除する"
          },
          "title": "情報リクエストを削除する",
          "label": "情報リクエスト"
        },
        "sharefile_package": {
          "delete": {
            "failed": "パッケージを削除できませんでした",
            "success": "パッケージを削除しました",
            "title": "パッケージを削除する"
          },
          "delete_from": {
            "try_again": "パッケージから削除するには、再試行してください",
            "confirm": "{<name>} を削除してもよろしいですか? これにより、パッケージから削除されます。",
            "confirm_container": "{<name>} を削除してもよろしいですか? これにより、パッケージから関連するものすべてを含めて削除されます。"
          },
          "label": "パッケージ"
        },
        "sharefile_project": {
          "delete": {
            "failed": "プロジェクトを削除できませんでした",
            "success": "プロジェクトを削除しました",
            "title": "プロジェクトを削除する"
          },
          "label": "プロジェクト"
        },
        "sharefile_requestlist": {
          "delete": {
            "failed": "ドキュメント リクエストを削除できませんでした",
            "success": "ドキュメント リクエストを削除しました",
            "title": "ドキュメント リクエストを削除する"
          },
          "label": "ドキュメント リクエスト"
        },
        "sharefile_template": {
          "delete": {
            "failed": "テーブルを削除できませんでした",
            "success": "テンプレートを削除しました",
            "title": "テンプレートを削除する"
          },
          "delete_from": {
            "try_again": "テンプレートから削除するには、再試行してください",
            "confirm": "{<name>} を削除してもよろしいですか? これにより、テンプレートから削除されます。",
            "confirm_container": "{<name>} を削除してもよろしいですか? これにより、パッケージから関連するものすべてを含めて削除されます。"
          },
          "label": "テンプレート"
        },
        "sharefile_todos": {
          "delete": {
            "failed": "タスクを削除できませんでした",
            "success": "タスクを削除しました",
            "title": "タスクの削除"
          },
          "label": "タスク"
        }
      }
    }
  },
  {
    "key": "nl",
    "content": {
      "common": {
        "cancel": "Annuleren",
        "copy": "Kopiëren",
        "delete": "Verwijderen",
        "delete_success": "Verwijdering is voltooid",
        "delete_failed": "Verwijderen mislukt",
        "duplicate": "Dupliceren",
        "edit": "Bewerken",
        "preview": "Voorbeeldweergave",
        "resources": "Bronnen",
        "save": "Opslaan",
        "save_changes": "Wijzigingen opslaan",
        "unknown": "onbekend",
        "menu": "Menu",
        "create_new": "Nieuwe maken"
      },
      "messages": {
        "error_loading_resources": "Uw bronnen konden niet worden geladen. Probeer het opnieuw.",
        "error_saving_json": "Uw bewerkingen konden niet worden opgeslagen",
        "error_saving_changes": {
          "message": "Uw wijzigingen konden niet worden opgeslagen",
          "description": "Probeer het opnieuw."
        },
        "no_resource_selected": "U hebt niets geselecteerd",
        "saved_changes": "Opgeslagen wijzigingen",
        "select_resource": "Kies iets in het zijpaneel om te bekijken of te bewerken, of maak iets nieuws.",
        "unable_to_edit": "Deze bron is momenteel niet beschikbaar om te bewerken. Selecteer in plaats daarvan een andere bron om te bewerken."
      },
      "prompts": {
        "edit_package": "Pakket bewerken",
        "exit_editor": {
          "cancel": "Sluiten zonder op te slaan",
          "message": "Weet u zeker dat u deze pagina wilt sluiten? Alle niet-opgeslagen wijzigingen gaan verloren.",
          "ok": "Opslaan en sluiten",
          "title": "Afsluiten zonder op te slaan"
        },
        "save_edits": "Bewerkingen opslaan",
        "show_code": "Code weergeven"
      },
      "resource": {
        "sharefile_datatable": {
          "delete": {
            "failed": "We konden de tabel niet verwijderen",
            "success": "Tabel verwijderd",
            "title": "Tabel verwijderen"
          },
          "label": "Tabel"
        },
        "sharefile_document": {
          "delete": {
            "failed": "We konden het document niet verwijderen",
            "success": "Verwijderd document",
            "title": "Document verwijderen"
          },
          "label": "Document"
        },
        "sharefile_flow": {
          "delete": {
            "failed": "We konden de werkstroom niet verwijderen",
            "success": "Werkstroom verwijderd",
            "title": "Werkstroom verwijderen"
          },
          "label": "Werkstroom"
        },
        "sharefile_inforeq": {
          "delete": {
            "failed": "We konden de informatieaanvraag niet verwijderen",
            "success": "Informatieaanvraag verwijderd",
            "title": "Informatieaanvraag verwijderen"
          },
          "title": "Informatieaanvraag verwijderen",
          "label": "Informatieaanvraag"
        },
        "sharefile_package": {
          "delete": {
            "failed": "We konden het pakket niet verwijderen",
            "success": "Pakket verwijderd",
            "title": "Pakket verwijderen"
          },
          "delete_from": {
            "try_again": "Probeer het opnieuw om het uit het pakket te verwijderen",
            "confirm": "Weet u zeker dat u {<name>} wilt verwijderen? Hiermee verwijdert u het uit het pakket.",
            "confirm_container": "Weet u zeker dat u {<name>} wilt verwijderen? Hiermee verwijdert u het en alles wat erbij hoort uit het pakket."
          },
          "label": "Pakket"
        },
        "sharefile_project": {
          "delete": {
            "failed": "We konden het project niet verwijderen",
            "success": "Project verwijderd",
            "title": "Project verwijderen"
          },
          "label": "Project"
        },
        "sharefile_requestlist": {
          "delete": {
            "failed": "We konden de documentaanvraag niet verwijderen",
            "success": "Documentaanvraag verwijderd",
            "title": "Documentaanvraag verwijderen"
          },
          "label": "Documentaanvraag"
        },
        "sharefile_template": {
          "delete": {
            "failed": "We konden het sjabloon niet verwijderen",
            "success": "Sjabloon verwijderd",
            "title": "Sjabloon verwijderen"
          },
          "delete_from": {
            "try_again": "Probeer het opnieuw om het uit het sjabloon te verwijderen",
            "confirm": "Weet u zeker dat u {<name>} wilt verwijderen? Hiermee verwijdert u het uit het sjabloon.",
            "confirm_container": "Weet u zeker dat u {<name>} wilt verwijderen? Hiermee verwijdert u het en alles wat erbij hoort uit het pakket."
          },
          "label": "Sjabloon"
        },
        "sharefile_todos": {
          "delete": {
            "failed": "We konden de taak niet verwijderen",
            "success": "Taak verwijderd",
            "title": "Taak verwijderen"
          },
          "label": "Taken"
        }
      }
    }
  },
  {
    "key": "pt-BR",
    "content": {
      "common": {
        "cancel": "Cancelar",
        "copy": "Copiar",
        "delete": "Excluir",
        "delete_success": "Exclusão concluída com sucesso",
        "delete_failed": "Falha na exclusão",
        "duplicate": "Duplicado",
        "edit": "Editar",
        "preview": "Visualizar",
        "resources": "Recursos",
        "save": "Salvar",
        "save_changes": "Salvar alterações",
        "unknown": "desconhecido",
        "menu": "Menu",
        "create_new": "Criar novo"
      },
      "messages": {
        "error_loading_resources": "Não foi possível carregar seus recursos. Tente novamente.",
        "error_saving_json": "Não foi possível salvar suas edições",
        "error_saving_changes": {
          "message": "Não foi possível salvar suas alterações",
          "description": "Tente novamente."
        },
        "no_resource_selected": "Você não tem nada selecionado",
        "saved_changes": "Alterações salvas",
        "select_resource": "Escolha algo no painel lateral para exibir ou editar, ou crie algo novo.",
        "unable_to_edit": "No momento, esse recurso não está disponível para edição. Selecione um recurso diferente para editar."
      },
      "prompts": {
        "edit_package": "Editar pacote",
        "exit_editor": {
          "cancel": "Fechar sem salvar",
          "message": "Tem certeza de que deseja fechar esta página? Todas as alterações não salvas serão perdidas.",
          "ok": "Salvar e fechar",
          "title": "Sair sem salvar"
        },
        "save_edits": "Salvar edições",
        "show_code": "Mostrar código"
      },
      "resource": {
        "sharefile_datatable": {
          "delete": {
            "failed": "Não foi possível excluir a tabela",
            "success": "Tabela excluída",
            "title": "Excluir tabela"
          },
          "label": "Tabela"
        },
        "sharefile_document": {
          "delete": {
            "failed": "Não foi possível excluir o documento",
            "success": "Documento excluído",
            "title": "Excluir documento"
          },
          "label": "Documento"
        },
        "sharefile_flow": {
          "delete": {
            "failed": "Não foi possível excluir o fluxo de trabalho",
            "success": "Fluxo de trabalho excluído",
            "title": "Excluir fluxo de trabalho"
          },
          "label": "Fluxo de trabalho"
        },
        "sharefile_inforeq": {
          "delete": {
            "failed": "Não foi possível excluir a solicitação de informações",
            "success": "Solicitação de informações excluída",
            "title": "Excluir solicitação de informações"
          },
          "title": "Excluir solicitação de informações",
          "label": "Solicitação de informações"
        },
        "sharefile_package": {
          "delete": {
            "failed": "Não foi possível excluir o pacote",
            "success": "Pacote excluído",
            "title": "Excluir pacote"
          },
          "delete_from": {
            "try_again": "Tente removê-lo novamente do pacote",
            "confirm": "Tem certeza de que deseja excluir {<name>}? Isso o removerá do pacote.",
            "confirm_container": "Tem certeza de que deseja excluir {<name>}? Isso removerá o arquivo e tudo o que pertence a ele do pacote."
          },
          "label": "Pacote"
        },
        "sharefile_project": {
          "delete": {
            "failed": "Não foi possível excluir o projeto",
            "success": "Projeto excluído",
            "title": "Excluir projeto"
          },
          "label": "Projeto"
        },
        "sharefile_requestlist": {
          "delete": {
            "failed": "Não foi possível excluir a solicitação de documento",
            "success": "Solicitação de documento excluída",
            "title": "Excluir solicitação de documento"
          },
          "label": "Solicitação de documento"
        },
        "sharefile_template": {
          "delete": {
            "failed": "Não foi possível excluir o modelo",
            "success": "Modelo excluído",
            "title": "Excluir modelo"
          },
          "delete_from": {
            "try_again": "Tente removê-lo novamente do modelo",
            "confirm": "Tem certeza de que deseja excluir {<name>}? Isso o removerá do modelo.",
            "confirm_container": "Tem certeza de que deseja excluir {<name>}? Isso removerá o arquivo e tudo o que pertence a ele do pacote."
          },
          "label": "Modelo"
        },
        "sharefile_todos": {
          "delete": {
            "failed": "Não foi possível excluir a tarefa",
            "success": "Tarefa excluída",
            "title": "Excluir tarefa"
          },
          "label": "Tarefa"
        }
      }
    }
  }
];