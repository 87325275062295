import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { logError } from '../../../logger';
import {
	GetPackagesParams,
	GetPackagesResponse,
	PackageSdkConfiguration,
	UpdatePackageParams,
	UpdatePackageResponse,
} from './types';

export interface PackageSdk {
	getPackages: (params: GetPackagesParams) => Promise<GetPackagesResponse>;
	updatePackage: (params: UpdatePackageParams) => Promise<UpdatePackageResponse>;
}

export function initializeSdk(configuration: PackageSdkConfiguration): PackageSdk {
	const sharedConfig: AxiosRequestConfig = {
		baseURL: configuration.baseUrl,
		timeout: 60000,
		headers: {
			'x-bff-csrf': 'true',
		},
	};

	const packageApi = axios.create(sharedConfig);

	return {
		getPackages: async (params: GetPackagesParams) => {
			return apiProxy<GetPackagesResponse>(async () => {
				return packageApi.post('/v2/packages/get', {
					containerRID: params.containerRID,
				});
			}, 'getPackage');
		},

		updatePackage: async (params: UpdatePackageParams) => {
			return apiProxy<UpdatePackageResponse>(async () => {
				return packageApi.post('/v2/packages/update', {
					RID: params.RID,
					state: params.definition,
				});
			}, 'updatePackage');
		},
	};
}

async function apiProxy<T>(
	proxyFn: () => Promise<AxiosResponse<T>>,
	proxyLabel: string
): Promise<T> {
	let response: AxiosResponse<T, any>;
	try {
		response = await proxyFn();
	} catch (error) {
		logError(error, {
			customMessage: `Package API failure for ${proxyLabel}`,
		});
		throw error;
	}

	return response.data;
}
