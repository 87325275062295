import {
	localizeResourceTokenOrDefaultToken,
	localizeResourceTokenWithFallback,
	t,
} from '@utils';

const resourceAssets = (resourceType: string) => {
	const fallback = 'sharefile:package';

	return {
		// common
		label: () =>
			t(localizeResourceTokenOrDefaultToken(resourceType, 'label', 'common.unknown')),

		// delete (resource)
		delete_title: () =>
			t(
				localizeResourceTokenOrDefaultToken(resourceType, 'delete.title', 'common.delete')
			),
		delete_failed: () =>
			t(
				localizeResourceTokenOrDefaultToken(
					resourceType,
					'delete.failed',
					'common.delete_failed'
				)
			),
		delete_success: () =>
			t(
				localizeResourceTokenOrDefaultToken(
					resourceType,
					'delete.success',
					'common.delete_success'
				)
			),

		// delete (from container)
		delete_try_again: (opts?: any) =>
			t(
				localizeResourceTokenWithFallback(
					resourceType,
					'delete_from.try_again',
					fallback
				),
				opts
			),
		delete_confirm: (opts?: any) =>
			t(
				localizeResourceTokenWithFallback(resourceType, 'delete_from.confirm', fallback),
				opts
			),
		delete_confirm_container: (opts?: any) =>
			t(
				localizeResourceTokenWithFallback(
					resourceType,
					'delete_from.confirm_container',
					fallback
				),
				opts
			),
	};
};

export const getResourceAssets = (resourceType: string) => {
	return resourceAssets(resourceType);
};
