import { ResourceSpec } from '@sharefile/packaging-types';

const specEditorSupportedResourceTypes = [
	// 'sharefile:project',
	'sharefile:requestlist',
	'sharefile:inforeq',
];

const specEditorHiddenResourceTypes = [
	'sharefile:tag',
	'sharefile:view',
	'sharefile:reshandler',
];

export const supportedResourceTypesForCreateNew = [
	'sharefile:requestlist',
	'sharefile:inforeq',
];

export const hasSpecEditorSupport = (resourceType: string): boolean => {
	return specEditorSupportedResourceTypes.includes(resourceType);
};

export const isResourceCardDisplayable = (resource: ResourceSpec): boolean => {
	return !specEditorHiddenResourceTypes.includes(resource.type);
};
