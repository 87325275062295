import { configurePackageClient } from '@data/clients';
import {
	EditPackageOverlay,
	getDefaultDrawerProps,
	getDefaultModalProps,
} from '@overlays';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { setLogger } from './logger';
import { registerNonProdDevPages } from './nonProdDevPages';
import { packagePiletOverlays } from './sdk';
import { t, withDefaultProviders } from './utils';

/**
 * Sets up the Package Pilet, registering the necessary
 * components and extensions needed for the Pilet.
 * @param app Pilet API
 */
export function setup(app: PiletApi) {
	setLogger(app.sf.getLogger());
	configureApiClients(app);
	registerOverlays(app);
	registerExtensions(app);
	registerPages(app);
	handleDevToolingRegistrations(app);
}

function configureApiClients(app: PiletApi) {
	configurePackageClient(app);
}

function registerOverlays(app: PiletApi) {
	app.registerModal(
		packagePiletOverlays.editPackageModal,
		withDefaultProviders(app, props => (
			<EditPackageOverlay {...props} {...props.options} />
		)),
		getDefaultModalProps({
			titleText: t('prompts.edit_package'),
			isFullscreen: true,
			overflowYOverride: 'hidden',
		})
	);

	app.registerDrawer(
		packagePiletOverlays.editPackageDrawer,
		withDefaultProviders(app, props => (
			<EditPackageOverlay {...props} {...props.options} />
		)),
		getDefaultDrawerProps({
			titleText: t('prompts.edit_package'),
			width: '100%',
		})
	);
}

function registerExtensions(app: PiletApi) {}

function registerPages(app: PiletApi) {}

const handleDevToolingRegistrations = (app: PiletApi) => {
	if (process.env.NODE_ENV === 'development') {
		registerNonProdDevPages(app);
	}
};
