/* istanbul ignore file */
import { createScopedInstance, getLanguage } from '@citrite/translate';
import { languages } from '../locales';

export { getLanguage };

export const { t: tmp, exists: tmp2 } = createScopedInstance({
	resources: languages,
	namespace: 'package-pilet',
});

export const t: (str: string, opts?: any) => string = tmp;
export const exists: (
	key: string | string[],
	options?: { [key: string]: any }
) => boolean = tmp2;
